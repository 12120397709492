import React from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Box } from '@mui/material';

const stylesColumns = {
    border: 0,
    color: '#575757',
    fontFamily: 'Segoe UI',
    fontSize: '20px',
    fontWeight: 'bold'
}

const stylesRows = {
    border: 0,
    color: '#575757',
    fontFamily: 'Segoe UI',
    fontSize: '20px',
    fontStyle: 'normal'
}

const createColumn = ( value ) => {
    return createCell( value, stylesColumns );
}

const createRow = ( row ) => {
    // eslint-disable-next-line no-unused-vars
    const { id, ...rowWithoutId } = row;
    const cells = Object.values( rowWithoutId );
    
    return cells.map(( cell ) => (
        createCell( cell, stylesRows ) 
    ));
}

const createCell = ( value, styles ) => {
    return (
        <TableCell
            key={ value }
            sx={{ ...styles }}
        >
            { value }
        </TableCell>
    )
}

export const TableGenericComponent = ({
    bar = false,
    displayPagination = true,
    columns,
    rows,
    count,
    rowsPerPage,
    page,
    handleChange = function(){},
    handleClick = function(){}
}) => {
    const handleChangePage = ({ newPage }) => {
        handleChange( rowsPerPage, newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        handleChange( parseInt( event.target.value ), page );
    };

    const displayTable = rows.length ? 'block' : 'none';

    return (
        <Box sx={{ display: displayTable }}>
            { bar && <hr style={{ border: '1px solid #000' }} /> }
            <TableContainer sx={{ width: '100%', padding: '4% 0' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            { columns.map( ( column ) => ( createColumn( column ) ) ) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { rows.map((row) => (
                            <TableRow
                                key={ row.id }
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover':{
                                        backgroundColor: '#EDEBEC'
                                    },
                                }}
                                onClick={ () => handleClick(row.id) }
                            >
                                { createRow( row ) }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            { displayPagination &&
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={ count }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onPageChange={ handleChangePage }
                    onRowsPerPageChange={ handleChangeRowsPerPage }
                />
            }
        </Box>
    )
}

TableGenericComponent.propTypes = {
    bar: PropTypes.bool,
    displayPagination: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    handleChange: PropTypes.func,
    handleClick: PropTypes.func
}