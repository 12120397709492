import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { Sync } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { RoutesDetailsContext } from '../../Contexts/BuildContext';

export const RoutesDatailsHeader = () => {
    const { route } = useContext( RoutesDetailsContext );

    const { locationName, creationDate } = route;
    
    return (
        <Grid container>
            <Grid item xs={ 7 }>
                <Typography variant="h4" color="text.secondary">
                    { locationName }
                </Typography>
                <Typography color="text.secondary">
                    Ubicación
                </Typography>
            </Grid>
            <Grid item xs={ 2 }>
                <Typography color="text.secondary" sx={{ fontSize: 14, fontWeight: 'normal' }}>
                    Te sugerimos confirmar los datos antes de sincronizar
                </Typography>
            </Grid>
            <Grid item xs={ 3 } sx={{ padding: '0 2%' }}>
                <LoadingButton sx={{
                    textTransform: 'none',
                    width: '100%',
                    '&:hover': {
                        backgroundColor: '#A2FF69',
                    }
                }}>
                    <Typography color="text.secondary" sx={{
                        fontSize: 17,
                        fontWeight: 'normal',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '1%'
                    }}>
                        <Sync />Sincronizar Match
                    </Typography>
                </LoadingButton>
            </Grid>
            <Grid item xs={ 12 }>
                <Typography variant="h4" color="text.secondary">
                    { moment(creationDate).format('DD / MM / yyyy') }
                </Typography>
                <Typography color="text.secondary">
                    Fecha
                </Typography>
            </Grid>
        </Grid>
    )
}