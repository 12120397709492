import React from 'react';
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { QuerysState } from '../../Contexts/QuerysState';
import { QuerysFilter } from './QuerysFilter';
import { QuerysTable } from './QuerysTable';

export const QuerysPage = () => {
    return (
        <Container>
            <Box sx={{ padding: '6% 0 0' }}>
                <Typography variant="h4" sx={{ fontWeight: 700 }} paddingBottom={ 4 }>Consultas de relevamientos</Typography>
                <Typography variant="h6" sx={{ color: '#575757' }}>Filtrá los relevamientos realizados</Typography>
            </Box>
            <Box>
                <QuerysState>
                    <QuerysFilter />
                    <QuerysTable />
                </QuerysState>
            </Box>
        </Container>
    )
}