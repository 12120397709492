import React, { useContext } from 'react';
import { Box } from '@mui/system';
import { RoutesDetailsContext } from '../../Contexts/BuildContext';
import { AccordionComponent } from '../../Components/Accordion/AccordionComponent';

const columnsMatch = ['TAG', 'Nombre', 'Serial'];
const columnsWithNoLocation = ['TAG', 'Nombre', 'Fechas','Serial'];
const columnsNotFoundInInsight = ['TAG'];
const columnsWithOtherLocation = ['TAG', 'Nombre', 'Serial', 'Ubicación'];
const columnsWithNoLink = ['TAG'];

export const RoutesDetailsAccordionList = () => {
    const {
        match,
        withNoLocation,
        notFoundInInsight,
        withOtherLocation,
        withNoLink
    } = useContext( RoutesDetailsContext );
    return (
        <Box>
            <AccordionComponent
                columns={ columnsMatch }
                rows={ match }
                summary="Match"
            />

            <AccordionComponent
                columns={ columnsWithNoLocation }
                rows={ withNoLocation }
                summary="Activos faltantes en la ubicación"
            />

            <AccordionComponent
                columns={ columnsNotFoundInInsight }
                rows={ notFoundInInsight }
                summary="Items no encontrados en Sistema Inventario"
            />

            <AccordionComponent
                columns={ columnsWithOtherLocation }
                rows={ withOtherLocation }
                summary="Items con otra ubicación en sistema inventario"
            />
            
            <AccordionComponent
                columns={ columnsWithNoLink }
                rows={ withNoLink }
                summary="Items sin Vinculación"
            />
        </Box>
    )
}