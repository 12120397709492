import React from 'react';
import PropTypes from 'prop-types';

import { Navigate, Outlet } from 'react-router-dom';


export const PrivateRoutes = ({
    auth
}) => {
    return auth ? <Outlet /> : <Navigate to ="/login" />
}

PrivateRoutes.propTypes = {
    auth: PropTypes.bool.isRequired
}