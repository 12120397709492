import React, { useContext, useEffect } from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import { Box } from '@mui/system';
import { FormHelperText, Grid, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styles } from '../../Components/GlobalTemplates/styles';
import { QuerysContext } from '../../Contexts/BuildContext';

export const QuerysFilter = () => {
    const {
        initialFormValues,
        formValues,
        handleFormValuesChange,
        handleQueryItems,
        querysPerPage,
        page,
        locations,
        handleQueryLocations
    } = useContext( QuerysContext );
    
    const {
        startDate,
        endDate,
        location,
        personal,
        tag
    } = formValues;

    useEffect(() => {
        handleQueryLocations();
    }, []);

    const { promiseInProgress } = usePromiseTracker();
    
    const classes = styles();
    return (
        <Box>
            <Box sx={{ padding: '2% 0' }}>
                <Grid container>
                    <Grid item sm={ 6 } md={ 3 } sx={{ marginTop: '2%' }}>
                        <FormHelperText id="startDate-helper-text">Desde el</FormHelperText>
                        <TextField
                            id="startDate"
                            name="startDate"
                            type="date"
                            value={ startDate }
                            onChange={ handleFormValuesChange }
                        />
                    </Grid>
                    <Grid item sm={ 6 } md={ 3 } sx={{ marginTop: '2%' }}>
                        <FormHelperText id="endDate-helper-text">Hasta el</FormHelperText>
                        <TextField
                            id="endDate"
                            name="endDate"
                            type="date"
                            value={ endDate }
                            onChange={ handleFormValuesChange }
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="flex-end"
                >
                    <Grid item sm={ 6 } md={ 3 } sx={{ marginTop: '2%' }}>
                        <TextField
                            id="location"
                            name="location"
                            select
                            label="Ubicación"
                            sx={{ width: 195 }}
                            value={ location }
                            onChange={ handleFormValuesChange }
                        >
                            {
                                locations.map(({ id, name }) => (
                                    <MenuItem key={ id } value={ id }>
                                        { name }
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item sm={ 6 } md={ 3 } sx={{ marginTop: '2%' }}>
                        <TextField
                            id="personal"
                            label="Personal"
                            sx={{ width: 195 }}
                            value={ personal }
                            onChange={ handleFormValuesChange }
                        />
                    </Grid>
                    <Grid item sm={ 6 } md={ 3 } sx={{ marginTop: '2%' }}>
                        <TextField
                            id="tag"
                            name="tag"
                            label="TAG"
                            sx={{ width: 195 }}
                            value={ tag }
                            onChange={ handleFormValuesChange }
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ margin: '2% 0' }}>
                <LoadingButton
                    variant="outlined"
                    className={ classes.relevamientosButton }
                    disabled={ JSON.stringify(initialFormValues) === JSON.stringify(formValues) }
                    onClick={() =>  handleQueryItems( formValues, querysPerPage, page, location ) }
                    loading={ promiseInProgress }
                    sx={{
                        backgroundColor: 'white',
                        border: '1px solid #252F80',
                        color: '#252F80',
                        '&:hover': {
                            backgroundColor: '#252F80',
                            color: 'white',
                            border: 'none'
                        }
                    }}
                >
                    Consultar
                </LoadingButton>
            </Box>
        </Box>
    )
}