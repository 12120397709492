import React from 'react';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

// COMPONENTS STYLES
export const Container = styled('div')(() => ({
    position: 'absolute',
    width: '100%',
    height: '80%',
}));


export const Accordion = styled(( props ) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    border: `1px solid #EDEBEC`,
    borderRadius: 5,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));
  
export const AccordionSummary = styled(( props ) => (
    <MuiAccordionSummary
        expandIcon={ <ArrowForwardIosSharpIcon /> }
        {...props}
    />
))(() => ({
    backgroundColor: '#EDEBEC',
    flexDirection: 'row-reverse',
    padding: 0,
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)',
        color: '#000',
        fontSize: '2rem',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper > .MuiSvgIcon-root': {
        height: '2em',
        width: '2.5em',
    }
}));