import React, { useContext } from 'react';
import { QuerysContext } from '../../Contexts/BuildContext';
import { TableGenericComponent } from '../../Components/TableGeneric/TableGenericComponent';

const columns = [ 'TAG RFID', 'Fecha Match', 'Ubicación' ];

export const QuerysTable = () => {
    const {
        querys,
        count,
        querysPerPage,
        page,
        handlePageChange
    } = useContext( QuerysContext );
    
    return (
        <TableGenericComponent
            bar={ true }
            columns={ columns }
            rows={ querys }
            count={ count }
            rowsPerPage={ querysPerPage }
            page={ page }
            handleChange={ handlePageChange }
        />
    )
}