import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { AppRoutes } from '../Routes/AppRoutes';
import { themeGlobal as theme } from '../Components/GlobalTemplates/themeGlobal';

export const MainPage = () => {
    return (
        <ThemeProvider theme={ theme }>
            <AppRoutes />
        </ThemeProvider>
    );
};

