import React from 'react';
import PropTypes from 'prop-types';

import { Navigate, Outlet } from 'react-router-dom';


export const PublicRoutes = ({
    auth
}) => {
    return !auth ? <Outlet /> : <Navigate to ="/" />
}

PublicRoutes.propTypes = {
    auth: PropTypes.bool.isRequired
}