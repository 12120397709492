import React from 'react';
import AppState from './Contexts/AppState'
import { MainPage } from './Pages/MainPage';

function App() {
  return (
    <AppState>
      <MainPage />
    </AppState>
  );
}

export default App;
