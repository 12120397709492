import React, { useContext, useEffect } from 'react';
import { usePromiseTracker } from "react-promise-tracker";

import { FormHelperText, Grid, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';

import { RoutesContext } from '../../Contexts/BuildContext';
import { styles } from '../../Components/GlobalTemplates/styles';

export const RoutesFilter = () => {
    const {
        formValues,
        routesPerPage,
        page,
        locations,
        handleFormValuesChange,
        handleQueryRoutes,
        handleQueryLocations
    } = useContext( RoutesContext );
    
    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {
        handleQueryLocations();
    }, []);
    
    const {
        startDate,
        endDate,
        location,
        personal
    } = formValues;

    const classes = styles();

    return (
        <Box>
            <Box sx={{ padding: '4% 0' }}>
                <Grid container spacing={ 2 }>
                    <Grid item sm={ 6 } md={ 3 }>
                        <FormHelperText id="startDate-helper-text">Desde el</FormHelperText>
                        <TextField
                            id="startDate"
                            name="startDate"
                            type="date"
                            value={ startDate }
                            onChange={ handleFormValuesChange }
                        />
                    </Grid>
                    <Grid item sm={ 6 } md={ 3 }>
                        <FormHelperText id="endDate-helper-text">Hasta el</FormHelperText>
                        <TextField
                            id="endDate"
                            name="endDate"
                            type="date"
                            value={ endDate }
                            onChange={ handleFormValuesChange }
                        />
                    </Grid>
                    <Grid item sm={ 6 } md={ 3 } sx={{ marginTop: '20px' }}>
                        <TextField
                            id="location"
                            name="location"
                            select
                            label="Ubicación"
                            sx={{ width: 200 }}
                            value={ location }
                            onChange={ handleFormValuesChange }
                        >
                            {
                                locations.map(({ id, name }) => (
                                    <MenuItem key={ id } value={ id }>
                                        { name }
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item sm={ 6 } md={ 3 } sx={{ marginTop: '20px' }}>
                        <TextField
                            id="personal"
                            label="Personal"
                            sx={{ width: 200 }}
                            value={ personal }
                            onChange={ handleFormValuesChange }
                        />
                    </Grid>
                </Grid>
            </Box>
            
            <Box sx={{ padding: '4% 0' }}>
                <LoadingButton
                    variant="outlined"
                    className={ classes.relevamientosButton }
                    onClick={() =>  handleQueryRoutes( formValues, routesPerPage, page, location ) }
                    loading={ promiseInProgress }
                    sx={{
                        backgroundColor: 'white',
                        border: '1px solid #252F80',
                        color: '#252F80',
                        '&:hover': {
                            backgroundColor: '#252F80',
                            color: 'white',
                            border: 'none'
                        }
                    }}
                >
                    Consultar
                </LoadingButton>
            </Box>
        </Box>
    )
}