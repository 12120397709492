import { axiosWithToken } from "./restCallBase.service";

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_AYSA;

export const routesGetAll = async ({ startDate, endDate, location, newRowPerPage, newPage }) => {

    let params = '?';
    startDate && ( params += `StartDate=${ startDate }&` );
    endDate && ( params += `EndDate=${ endDate }&` );
    location && ( params += `LocationId=${ location }&` );
    params += `PageSize=${ newRowPerPage }&Page=${ newPage }&`;

    const url = `${ baseUrl }/route${ params }`;
    return await axiosWithToken( url ) ;
}

export const routesGetOne = async ( id, data ) => {
    const url = `${ baseUrl }/route/details/${ id }`;
    return await axiosWithToken( url, data );
}

export const routesGetByItems = async ({ startDate, endDate, location, newRowPerPage, newPage }) => {

    let params = '?';
    startDate && ( params += `StartDate=${ startDate }&` );
    endDate && ( params += `EndDate=${ endDate }&` );
    location && ( params += `LocationId=${ location }&` );
    params += `PageSize=${ newRowPerPage }&Page=${ newPage }&`;

    const url = `${ baseUrl }/route/items${ params }`;
    return await axiosWithToken( url ) ;
}