import React, { useContext } from 'react';
import { RoutesContext } from '../../Contexts/BuildContext';
import { TableGenericComponent } from '../../Components/TableGeneric/TableGenericComponent';

const columns = [ 'Estado', 'Ubicación', 'Fecha de Relevamiento', 'Personal' ];

export const RoutesTable = () => {
    const {
        routes,
        routesPerPage,
        count,
        page,
        handlePageChange,
        handleClick
    } = useContext( RoutesContext );
    return (
        <TableGenericComponent
            bar={ true }
            columns={ columns }
            rows={ routes }
            count={ count }
            rowsPerPage={ routesPerPage }
            page={ page }
            handleChange={ handlePageChange }
            handleClick={ handleClick }
        />
    )
}
