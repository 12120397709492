import React from 'react';

import { Box, Container, Typography } from '@mui/material';

import { RoutesFilter } from './RoutesFilter';
import { RoutesState } from '../../Contexts/RoutesState';
import { RoutesTable } from './RoutesTable';

export const RoutesPage = () => {
    return (
        <Container>
            <Box sx={{ padding: '6% 0 0' }}>
                <Typography variant="h4" sx={{ fontWeight: 700 }} paddingBottom={ 4 }>Búsqueda de relevamientos</Typography>
                <Typography variant="h6" sx={{ color: '#575757' }}>Filtrá los relevamientos realizados</Typography>
            </Box>
            <Box>
                <RoutesState>
                    <RoutesFilter />
                    <RoutesTable />
                </RoutesState>
            </Box>
        </Container>
    )
}