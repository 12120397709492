import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails } from '@mui/material';
import { Accordion, AccordionSummary } from '../GlobalTemplates/componentsStyles';
import { TableGenericComponent } from '../TableGeneric/TableGenericComponent';

export const AccordionComponent = ({ columns, rows, summary }) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={ <ExpandMoreIcon /> }
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography component={ 'span' }>
                    { summary }
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TableGenericComponent
                    columns={ columns }
                    rows={ rows }
                    rowsPerPage={ rows.length }
                    page={ 0 }
                    count={ rows.length }
                    displayPagination={ false }
                />
            </AccordionDetails>
        </Accordion>
    )
}

AccordionComponent.propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    summary: PropTypes.string.isRequired
}