import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { RoutesDetailsContext } from './BuildContext';
import { routesGetOne } from '../Services/restCallRoute.service';
import { trackPromise } from 'react-promise-tracker';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

const NotFoundInInsight = ({ tag }) => {
    return(
        <Typography sx={{
            color: '#E36D00',
            fontSize: '20px',
            fontWeight: 500 }}
        >
            { tag }
        </Typography>
    );
}

const WithoutTag = () => {
    return(
        <Typography sx={{ color: '#E36D00', fontSize: '15px' }}>
            Sin TAG Asignado
        </Typography>
    )
}

export const RoutesDetailsState = ({ children }) => {
    const { id } = useParams();
    const routeId = id;

    const initialState = {
        route: {},
        match: [],
        withNoLocation: [],
        notFoundInInsight: [],
        withOtherLocation: [],
        withNoLink: []
    };

    const [ state, setState ] = useState( initialState );

    const handleQueryRoutesDetails = async () => {
        const data = await trackPromise( routesGetOne( routeId ) );
        if(data){
            const match = data.match.map(({ id, tag, name, serial }) => ({
                id: id,
                tag: !tag ? <WithoutTag /> : tag,
                name: name,
                serial: serial
            }));
    
           const withNoLocation = data.withNoLocation.map(({ id, tag, name, date, serial }) => ({
                id: id,
                tag: !tag ? <WithoutTag /> : tag,
                name: name,
                date: date,
                serial: serial
            }));
    
            const notFoundInInsight = data.notFoundInInsight.map(({ id, tag }) => ({
                id: id,
                tag: <NotFoundInInsight tag={ tag } />
            }));
    
            const withOtherLocation = data.withOtherLocation.map(({ id, tag, name, serial, location }) => ({
                id: id,
                tag: !tag ? <WithoutTag /> : tag,
                name: name,
                serial: serial,
                location: location
            }));
    
            const withNoLink = data.withNoLink.map(({ id, tag }) => ({
                id: id,
                tag: tag
            }));
    
            setState({
                ...state,
                route: data.route,
                match,
                withNoLocation,
                notFoundInInsight,
                withOtherLocation,
                withNoLink
            });
        }
    }

    return (
        <RoutesDetailsContext.Provider value={{
            route: state.route,
            match: state.match,
            withNoLocation: state.withNoLocation,
            notFoundInInsight: state.notFoundInInsight,
            withOtherLocation: state.withOtherLocation,
            withNoLink: state.withNoLink,
            handleQueryRoutesDetails
        }}>
            { children }
        </RoutesDetailsContext.Provider>
    );
}

NotFoundInInsight.propTypes = {
    tag: PropTypes.string.isRequired
}

RoutesDetailsState.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}