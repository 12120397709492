import { axiosWithoutToken, axiosWithToken } from "./restCallBase.service";

// eslint-disable-next-line no-undef
const baseUrl = process.env.REACT_APP_API_AUTH;

export const login = async ( data ) => {
    const url = `${ baseUrl }/user/login`;
    return await axiosWithoutToken( url, data, 'POST' );
}

export const refreshToken = async () => {
    const url = `${ baseUrl }/refrestoken`;
    return await axiosWithToken( url, {}, 'POST' );
}